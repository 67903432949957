$(document).ready(function() {
  $("#sidebar-toggle").click(function() {
    $('#sidebar').hide();
    $("#sidebar-skinny").show();

    $.post('/set_sidebar_cookie', { sidebar: 'skinny' });
  });

  $("#sidebar-skinny .sidebar-header").click(function() {
    $('#sidebar-skinny').hide();
    $("#sidebar").show();

    $.post('/set_sidebar_cookie', { sidebar: 'normal' });
  });


  $("#sidebar-skinny .sidebar-header").hover(
    function() {
      $('.skinny-emblem').hide();
      $('.skinny-emblem-hover').show();
    }, function() {
      $('.skinny-emblem').show();
      $('.skinny-emblem-hover').hide();
    }
  );
});
