// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("chartkick")
require("chart.js")
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require('sidebar')
require('datatables.net-bs4')

import 'bootstrap/dist/js/bootstrap';
import $ from 'jquery';
global.$ = jQuery;

$(document).ready(function() {
  $('#score-table').DataTable({
    'bLengthChange': false,
    "order": [[ 0, "desc" ]]
  });

  $('#notifications').DataTable({
    'bLengthChange': false,
    "order": [[ 0, "desc" ]]
  });

  $('.datatable').DataTable({
    bLengthChange: false,
    pageLength: 10,
    order: [[ 1, 'asc' ]],
    columnDefs: [
      { targets: 'no-sort', orderable: false }
    ]
  });

  $('.groups-datatable').DataTable({
    bLengthChange: false,
    pageLength: 15,
    searching: false,
    order: [[ 1, 'asc' ]],
    columnDefs: [
      { targets: 'no-sort', orderable: false }
    ]
  });
});
